import React from "react";
import { Link, graphql } from "gatsby";

export default function Home({ data }) {
  return (
    <div className="blogs-preview-wrapper">
      <h1>Welcome to my blog</h1>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <article key={node.id} className="blog-preview">
          <p className="date">{node.frontmatter.date}</p>
          <Link to={node.fields.slug}>
            <h2>{node.frontmatter.title}</h2>
          </Link>
          <p className="summary">{node.frontmatter.description}</p>
        </article>
      ))}
    </div>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blogs)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
